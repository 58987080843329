<template>
  <div
    class="lottery-container"
    style="height:100%"
  >
    <div
      class="lottery-body col-center"
      style="width:100%"
    >
      <p style="font-size: 1.25rem;
    font-family: lantinghei;
    font-weight: 700;
    color: #f3cdb6;
    margin-bottom: 1.125rem;">获 奖 名 单</p>
      <div class="block-list">
        <!-- <div class="block-list-title row-center"></div> -->
        <el-table
          :data="blockList"
          align='center'
          size="small"
          style="width: 100%;"
        >
          <el-table-column
            prop="itemName"
            label="等级"
            align="center"
            width="61"
          >
          </el-table-column>
          <el-table-column
            prop="blockName"
            label="奖品"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.itemName=='一等奖'">PS5游戏机</span>
              <span v-else-if="scope.row.itemName=='二等奖'">VIFA音响</span>
              <span v-else-if="scope.row.itemName=='三等奖'">Pico Neo 3</span>
              <span v-else-if="scope.row.itemName=='四等奖'">观月望远镜</span>
              <span v-else> 拍拍月球灯</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="uid"
            label="UID"
            align='center'
            width="55"
          >
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            align='center'
            width="90"
          >
            <template slot-scope="scope">
              <span>{{ String(scope.row.phone).substring(0,3) + '****' + String(scope.row.phone).substring(String(scope.row.phone).length - 4,) }}</span>
            </template>
          </el-table-column>

        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import api from '../api/index-client'
import { Table,TableColumn } from 'element-ui';
Vue.use(Table)
Vue.use(TableColumn)

export default {
  data () {
    return {
      blockList: [],
    }
  },
  created () {
    this.$store.commit('HIDE_APPLOADING')
    this.getLotteryInfo() // 获取抽奖信息
  },
  methods: {
    getLotteryInfo () {
      api
        .get('lottery/getLotteryList')
        .then(result => {
          if (result.data.status == 200) {
            this.blockList = result.data.data
            this.$store.commit("HIDE_APPLOADING")
          }


        })
    },
  }
}
</script>